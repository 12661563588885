import React from "react";
import { PageSnapshotField } from "./PageSnapshotField";
import { FaBuilding, FaGlobe } from "react-icons/fa";

const CrawlScopeField = ({ crawlScope }) => {
    if (!crawlScope) {
        return null;
    }

    const scopeText = crawlScope === "INTERNAL" ? "Internal - this page was inside the domain/path configured for this crawl" :
        crawlScope === "EXTERNAL" ? "External - this page was outside the domain/path configured for this crawl" : "Unknown";

    const iconStyle = {
        marginRight: "0.4rem",
        fontSize: "1.2rem",
        color: crawlScope === "INTERNAL" ? "#20c997" :
            crawlScope === "EXTERNAL" ? "#0056b3" :
                "#adb5bd",
    };

    const scopeIcon = crawlScope === "INTERNAL" ? <FaBuilding style={iconStyle} /> :
        crawlScope === "EXTERNAL" ? <FaGlobe style={iconStyle} /> : null;

    return (
        <PageSnapshotField
            diffDisabled
            fieldName="Crawl Scope"
            fieldValueCur={
                <span style={{ display: "flex", alignItems: "center" }}>
                    {scopeIcon}
                    {scopeText}
                </span>
            }
        />
    );
};

export default CrawlScopeField;