import React from "react";
import { CheckCircle, ErrorOutline } from "@mui/icons-material";
import { PageSnapshotField } from "./PageSnapshotField";

const IndexabilityField = ({ cur }) => {
    const indexability = cur?.indexability;

    if (!indexability || indexability.isIndexable === undefined || indexability.isIndexable === null) {
        return null;
    }

    const isIndexable = indexability.isIndexable;
    const notIndexableReasons = indexability.notIndexableReasons || [];

    const reasonMap = {
        "BLOCKED_BY_ROBOTS_TXT": "Blocked by Robots.txt",
        "META_NOINDEX": "Meta Noindex",
        "CANONICALIZED": "Canonicalized to a different URL",
        "X_ROBOTS_NOINDEX": "X-Robots Noindex",
    };

    const mappedReasons = notIndexableReasons.map(reason => reasonMap[reason] || reason).join(", ");
    const icon = isIndexable ? <CheckCircle style={{ color: "green" }} /> : <ErrorOutline style={{ color: "orange" }} />;
    const text = isIndexable ? "Indexable" : `Non-Indexable: ${mappedReasons}`;

    return (
        <PageSnapshotField
            diffDisabled
            fieldName="Indexability"
            fieldValueCur={
                <>{icon} {text}</>
            }
        />
    );
};

export default IndexabilityField;