import * as qs from "querystring";
import React, {useEffect, useState} from "react";
import connect from "react-redux/es/connect/connect";
import {useHistory, useLocation, withRouter} from "react-router";
import {Button, Col, Collapse, FormGroup, Input, Label} from "reactstrap";
import './TableFilters.css';

import {Card} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {MdClear, MdSearch} from "react-icons/md";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {generateUrlForWorkspace} from "../common";
import {RecommendationsFilter} from "./commons/RecommendationsFilter";
import {StatusCodeFilters} from "./commons/StatusCodeFilters";
import MetaFilter from "./commons/MetaFilter";
import {ButtonContainer, ButtonWithIcon} from "./commons/commoncomponents";
import {getStateFromQuerystring, parseQuerystring, resolvePath} from "./commons/utilities";
import {useSelector} from "react-redux";


const NewTableFilters = () => {
    const history = useHistory();
    const location = useLocation();
    const workspaces = useSelector(state => state.workspaces);
    const curQueryString = parseQuerystring(location);
    const [state, setState] = useState({
        ...getStateFromQuerystring(curQueryString),
        collapse: curQueryString.diffs !== undefined || curQueryString.uriSearch !== undefined
    })

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            ...getStateFromQuerystring(curQueryString),
        }))
    }, [location]);

    const toggleCollapse = () => {
        setState((prev) => ({
            ...prev,
            collapse: !prev.collapse
        }));
    };


    const hasFiltersInQuery = () => {
        const parameters = Object.keys(curQueryString);
        for (let curParam of parameters) {
            if (curParam === "page") continue;

            if (curQueryString[curParam].length > 0) return true;
        }

        return false;
    }


    let clearButton = null;

    if (hasFiltersInQuery()) {
        const dashboardUrl = generateUrlForWorkspace(workspaces.current.seqNumber);
        clearButton = <ButtonWithIcon icon={MdClear} tag={Link} to={dashboardUrl}>Clear Filters</ButtonWithIcon>;
    }

    const buildUriSearchParameter = () => {
        if (!state.uriSearch) return "";
        let uriSearch = "uriSearch=";
        if (state.isRegexFilter) {
            uriSearch += state.isRegexFilter ? "isRegex:true|" : "";
        }
        uriSearch += "query:" + encodeURIComponent(state.uriSearch);
        return uriSearch;
    }


    function buildHttpStatusParameters() {
        let filters = "";
        filters += (state.toggles.status200Checked ? "200," : "");
        filters += (state.toggles.status301Checked ? "301," : "");
        filters += (state.toggles.status3xxChecked ? "300,302,303,304,305,307,307," : "");
        filters += (state.toggles.status4xxChecked ? "400,401,403,404," : "");
        filters += (state.toggles.status5xxChecked ? "500,501,503,504,505," : "");
        return filters;
    }

    function buildDifferencesParameters() {
        let filters = "";
        filters += (state.toggles.titleChecked ? "Title," : "");
        filters += (state.toggles.h1Checked ? "H1," : "");
        filters += (state.toggles.metaChecked ? "Meta," : "");
        filters += (state.toggles.redirectChecked ? "Redirect" : "");
        return filters;
    }

    const setUri = () => {
        let filters = "?";

        let uriSearch = buildUriSearchParameter();
        if (uriSearch) {
            filters += buildUriSearchParameter();
        }

        let diffParameters = buildDifferencesParameters();
        if (diffParameters) {
            filters += "&diffs=" + diffParameters;
        }

        let httpStatusParameters = buildHttpStatusParameters();
        if (httpStatusParameters) {
            filters += "&httpStatus=" + httpStatusParameters;
        }

        const resolvedURL = resolvePath(location.pathname, filters);
        history.push(resolvedURL);
    };


    useEffect(() => {
        if (state.uriSearch.length > 0) {
            setUri();
        }
    }, [state.isRegexFilter]);


    useEffect(() => {
        console.info("toggleUserInteractions", state.toggleUserInteractions);
        if (state.toggleUserInteractions) {
            //avoid changing uri on first page load
            setUri();
        }
    }, [state.toggleUserInteractions]);


    const handleIsRegexToggle = (e) => {
        const {checked} = e.target;
        setState((prevState) => ({...prevState, isRegexFilter: checked}));
    };


    const handleKeyPress = (target) => {
        if (target.charCode === 13) {
            onApply(target);
        }
    };
    const handleChange = ({target}) => {
        setState((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };

    const onApply = (e) => {
        e.preventDefault();
        setUri();
    };


    const handleSwitchToggle = (name) => (event) => {
        setState((prevState) => {
            return {
                ...prevState,
                toggleUserInteractions: (prevState?.toggleUserInteractions || 0) + 1,
                toggles: {
                    ...prevState.toggles,
                    [name]: event.target.checked,
                }
            }
        });
    };


    return (<>

            <ButtonContainer>
                <ButtonWithIcon icon={MdSearch} onClick={toggleCollapse} id="toggler">Advanced
                    Search</ButtonWithIcon>
                {clearButton}
            </ButtonContainer>

            <Collapse isOpen={state.collapse}>
                <div className={"filterBox"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card className="p-3">
                                <FormGroup check>
                                    <div className="d-flex border rounded"
                                         style={{background: "#f8f9fa", marginLeft: "-1.25rem"}}>
                                        <Input
                                            id="uriSearch"
                                            name="uriSearch"
                                            value={state.uriSearch}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPress}
                                            placeholder="Search URL..."
                                            className="border-0"
                                        />
                                        <Button onClick={onApply} color="link">
                                            <span className="ml-1">Search</span>
                                        </Button>
                                    </div>
                                </FormGroup>
                                <FormGroup check className="mt-3 ml-1">
                                    <Input
                                        type="checkbox"
                                        id="regexFilter"
                                        checked={state.isRegexFilter}
                                        onChange={handleIsRegexToggle}

                                    />
                                    <Label for="regexFilter" className="mt-1">Search with Regex</Label>
                                </FormGroup>
                            </Card>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <RecommendationsFilter handleSwitchToggle={handleSwitchToggle} state={state}/>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <StatusCodeFilters handleSwitchToggle={handleSwitchToggle} state={state}/>
                        </Grid>

                    </Grid>


                </div>


            </Collapse>
        </>
    );


};

export default NewTableFilters;