import PropTypes from "prop-types";
import React, { Component } from 'react';

import qs from 'querystring';
import { Col, Grid, Row } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { connect } from "react-redux";
import { fetchMonitoredUri } from "../../actions/monitoredUri";
import DashboardSimple from "../DashboardSimple";
import TableFilters from "./UriTable/TableFilters";
import UriTable from './UriTable/UriTable';
import './style.css';
import CrawlerControlPanel from "../CrawlerControlPanel";
import WorkspacePolling from "./WorkspacePolling";


class Workspace extends Component {


    constructor(props) {
        super(props);
        this.props.fetchMonitoredUri(this.props.location.search, this.props.match.params.workspaceNumber);
    }


    handlePageChange = (evt) => {
        const curQueryString = qs.parse(this.props.location.search.replace(/^\?/, ''));
        curQueryString.page = evt.selected;
        this.props.history.push("?" + qs.stringify(curQueryString));
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            // console.log("Route changed!");
            this.props.fetchMonitoredUri(this.props.location.search, this.props.match.params.workspaceNumber);
        }
    }


    render() {


        if (!this.props.tableData) {
            return null;
        }

        return (
            <Grid className="big-fat-grid">
                <WorkspacePolling workspaceId={this.props.workspaces.current.id} />
                <Row>
                    <Col xs={12} md={12}>
                        <DashboardSimple />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <CrawlerControlPanel />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <TableFilters />
                        <PaginateLinks pageCount={this.props.page.totalPages} forcePage={this.props.page.number}
                            onPageChange={this.handlePageChange} />
                        <UriTable tableData={this.props.tableData} filter={this.props.filter} />
                        <p />
                        <PaginateLinks pageCount={this.props.page.totalPages} forcePage={this.props.page.number}
                            onPageChange={this.handlePageChange} />
                    </Col>
                </Row>
            </Grid>
        )

    }
}

class PaginateLinks extends React.Component {
    render() {
        return (
            <ReactPaginate className="pagination"
                previousLabel={"Prev"}

                breakClassName={"page-link"}

                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}

                pageCount={this.props.pageCount}
                forcePage={this.props.forcePage}
                onPageChange={this.props.onPageChange}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
        );
    }
}


Workspace.propTypes = {
    urls: PropTypes.array
};

Workspace.defaultProps = {
    monitoredUrls: []
};


function mapsStateToProps(state) {
    return { ...state.monitoredUri, workspaces: { ...state.workspaces } };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fetchMonitoredUri: function (queryString, workspaceNumber) {
            dispatch(fetchMonitoredUri(queryString, workspaceNumber));
        }
    };
}


export default connect(mapsStateToProps, mapDispatchToProps)(Workspace);

