import React from "react";
import {Col, FormGroup, Label} from "reactstrap";
import Switch from "@material-ui/core/Switch";



const MetaFilter = ({name, onToggle, checked}) => {
    return (
        <FormGroup className={"v-center"}>
            <Label sm={9}>{name}</Label>
            <Col sm={3} className={"v-center"}>
                <Switch onChange={onToggle} checked={checked} id="normal-switch"/>
            </Col>
        </FormGroup>
    );
};

export default MetaFilter;
