import React from 'react';
import CardHeader from "@material-ui/core/CardHeader";
import {DashboardWidgetCard, FlexCardContent} from "./DashboardComponents";
import {Row} from "reactstrap";
import {BarChart} from "@mui/x-charts";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const chartSetting = {
    margin: {top: 0, bottom: 0, left: 0, right: 0},
    width: 250,
    height: 200
};

const CrawlScopeChart = () => {
    const workspaceStats = useSelector((state) => state.stats.workspaceStats);
    const crawlScopeStats = workspaceStats?.crawlScopeStats || {};
    const history = useHistory();

    const dataset = [
        {category: 'Internal', count: crawlScopeStats.internal || 0, path: '?crawlScope=internal'},
        {category: 'External', count: crawlScopeStats.external || 0, path: '?crawlScope=external'},
    ];

    const handleBarClick = (event, itemData) => {
        if (itemData && itemData.dataIndex !== undefined) {
            const clickedItem = dataset[itemData.dataIndex];
            if (clickedItem?.path) {
                history.push(clickedItem.path);
            }
        }
    };

    const tickLabelStyle = {
        fontSize: '1rem',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: 400,
        lineHeight: 1.334,
        marginRight: '2em',
    };


    return (
        <DashboardWidgetCard>
            <CardHeader title="Crawl Scope"/>
            <FlexCardContent>
                <Row>
                    <BarChart
                        dataset={dataset}
                        bottomAxis={null}
                        barLabel="value"
                        yAxis={[
                            {
                                disableTicks:true,
                                labelStyle: tickLabelStyle,
                                tickLabelStyle: tickLabelStyle,
                                tickFontSize: 15,
                                scaleType: 'band',
                                dataKey: 'category',
                                colorMap: {
                                    type: 'ordinal',
                                    colors: ['#3498db', '#2ecc71']
                                }
                            }
                        ]}
                        series={[
                            {
                                dataKey: 'count',

                            }
                        ]}
                        layout="horizontal"
                        barGapRatio={0.2}
                        onItemClick={handleBarClick}
                        {...chartSetting}
                    />
                </Row>
            </FlexCardContent>
        </DashboardWidgetCard>
    );
};

export default CrawlScopeChart;