import React from "react";
import {LinearProgress, Box, Grid} from "@mui/material";
import {CardHeader} from "@material-ui/core";
import {DashboardWidgetCard, FlexCardContent} from "./DashboardComponents";
import {Row} from "reactstrap";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

const StyledBox = styled(Box)({
    width: '100%',
    borderBottom: '1px solid #e0e0e0',
    padding: '8px 14px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
        borderBottom: 'none',
    },
    '&:hover': {
        backgroundColor: '#e8f4ff',
        transition: 'background-color 0.3s ease',
    },
    '&:active': {
        backgroundColor: '#dceffd',
    },
});

const StyledHeaderBox = styled(Box)({
    width: '100%',
    borderBottom: '1px solid #e0e0e0',
    padding: `0px 14px 0px 14px`,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
});


const LanguageCard = () => {
    const workspaceStats = useSelector((state) => state.stats.workspaceStats);
    const stats = workspaceStats?.indexabilityStats || {};
    const totalPages = workspaceStats?.statusCodeStats?.totalCount || 0;
    const history = useHistory();

    const dataset = [
        {category: 'Meta NoIndex', count: stats.metaNoIndex || 0, path: '?notIndexableReasons=META_NOINDEX'},
        {category: 'Canonicalized', count: stats.canonicalized || 0, path: '?notIndexableReasons=CANONICALIZED'},
        {category: 'Blocked by Robots.txt', count: stats.blockedByRobotsTxt || 0, path: '?notIndexableReasons=BLOCKED_BY_ROBOTS_TXT'},
        {category: 'X-Robots NoIndex', count: stats.xrobotsNoIndex || 0, path: '?notIndexableReasons=X_ROBOTS_NOINDEX'},
    ];

    const handleClick = (path) => {
        history.push(path);
    };

    return (
        <DashboardWidgetCard sx={{m: 2}}>
            <CardHeader title="Indexability Issues"/>
            <FlexCardContent sx={{px: 2, py: 1}}>
                <Row>
                    <StyledHeaderBox as="div">
                        <Grid container spacing={0} sx={{fontWeight: "bold", mb: 1}}>
                            <Grid item xs={6}>Category</Grid>
                            <Grid item xs={6}>Count</Grid>
                        </Grid>
                    </StyledHeaderBox>
                    {dataset.map((entry) => (
                        <StyledBox key={entry.category} onClick={() => handleClick(entry.path)}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>{entry.category}</Grid>
                                <Grid item xs={6}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item xs={8}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={(entry.count / totalPages) * 100}
                                                sx={{height: 12, borderRadius: 5}}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sx={{textAlign: 'right', fontWeight: 'bold'}}>
                                            {entry.count}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    ))}
                </Row>
            </FlexCardContent>
        </DashboardWidgetCard>
    );
};

export default LanguageCard;