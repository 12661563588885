import {Card} from "@material-ui/core";
import React from "react";
import MetaFilter from "./MetaFilter";


export const RecommendationsFilter = ({handleSwitchToggle, state}) => {
    const toggles = state.toggles;
    return (

        <Card>
            <p/>
            <MetaFilter name={"Title not implemented"} checked={toggles.titleChecked}
                        onToggle={handleSwitchToggle("titleChecked")}/>
            <MetaFilter name={"H1 not implemented"} checked={toggles.h1Checked}
                        onToggle={handleSwitchToggle("h1Checked")}/>
            <MetaFilter name={"Meta Description not impl"} checked={toggles.metaChecked}
                        onToggle={handleSwitchToggle("metaChecked")}/>
            <MetaFilter name={"Redirect not implemented"} checked={toggles.redirectChecked}
                        onToggle={handleSwitchToggle("redirectChecked")}/>
        </Card>
    );
}