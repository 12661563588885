import {Card} from "@material-ui/core";
import React from "react";
import MetaFilter from "./MetaFilter";


export const StatusCodeFilters = ({handleSwitchToggle, state}) => {
    const toggles = state.toggles;
    return (
        <Card>
            <p/>
            <MetaFilter name={"Status code 200"} checked={toggles.status200Checked}
                        onToggle={handleSwitchToggle("status200Checked")}/>
            <MetaFilter name={"Status code 301"} checked={toggles.status301Checked}
                        onToggle={handleSwitchToggle("status301Checked")}/>
            <MetaFilter name={"Status code 3xx"} checked={toggles.status3xxChecked}
                        onToggle={handleSwitchToggle("status3xxChecked")}/>
            <MetaFilter name={"Status code 4xx"} checked={toggles.status4xxChecked}
                        onToggle={handleSwitchToggle("status4xxChecked")}/>
            <MetaFilter name={"Status code 5xx"} checked={toggles.status5xxChecked}
                        onToggle={handleSwitchToggle("status5xxChecked")}/>
        </Card>
    );
}


