import styled from "styled-components";
import React from "react";
import {Button} from "reactstrap";

export const ButtonContainer = styled.div`
    .btn {
        margin-right: 1em;
        margin-bottom: 1em;
    }

    .icon-container {
        display: flex;
        vertical-align: middle
    }
`;

export class ButtonWithIcon extends React.Component {
    render() {
        let {tag, to, onClick, id} = this.props;
        return (
            <Button id={id} onClick={onClick} tag={tag} to={to} color="secondary" size="sm">
                <div className="icon-container">
                    {this.getIcon()}&nbsp; {this.props.children}
                </div>
            </Button>
        )
    };

    getIcon() {
        if (this.props.icon === undefined) return null;
        let Tag = this.props.icon;
        return <Tag size="1.5em"/>;
    }
}
