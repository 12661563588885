import qs from "querystring";

export function parseQuerystring(location) {
    return qs.parse(location.search.replace(/^\?/, ''));
}

export function getStateFromQuerystring(queryString) {
    const diffs = queryString.diffs || "";
    const httpStatus = queryString.httpStatus || "";
    const isRegexFilter = queryString.uriSearch?.includes("isRegex:true") || false;

    return {
        isRegexFilter: isRegexFilter,
        uriSearch: getUriSearch(queryString) || "",
        toggles: {
            titleChecked: diffs.includes("Title"),
            metaChecked: diffs.includes("Meta"),
            h1Checked: diffs.includes("H1"),
            h2Checked: diffs.includes("H2"),
            redirectChecked: diffs.includes("Redirect"),
            status200Checked: httpStatus.includes("200"),
            status301Checked: httpStatus.includes("301"),
            status3xxChecked: httpStatus.includes("302"),
            status4xxChecked: httpStatus.includes("404"),
            status5xxChecked: httpStatus.includes("500"),
        }
    }
}

function getUriSearch(curQueryString) {
    const queryMatch = curQueryString.uriSearch?.match(/query:([^|]+)/);
    return queryMatch ? queryMatch[1] : "";
}

export function resolvePath(from, to) {
    const resolvedURL = new URL(to, 'http://dummyhost' + from).href;
    return resolvedURL.replace('http://dummyhost', '');
}
