import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, withRouter} from "react-router";
import {Col} from "reactstrap";
import {fetchWorkspaceStats} from "../../actions/stats";
import {
    CountryFlagWrapper,
    DashboardContainer,
    DashboardRow,
    DashboardTitle,
    WorkspaceNameWrapper,
    WorkspaceTitle,
    WorkspaceUrl,
    WorkspaceUrlWrapper
} from "./DashboardComponents";
import IndexStatsWidget from "./IndexStatsWidget";
import RecommendationTracker from "./RecommendationTracker";
import {CountryFlag} from "../Workspace/CountryFlag";
import IndexabilityChart from "./IndexabilityChart";
import styled from "styled-components";
import IndexabilityCard from "./IndexabilityCard";
import CrawlScope from "./CrawlScope";

const MainContainer = styled.div`
    @media (min-width: 1024px) {
        max-width: 1700px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
`;


const DashboardSimple = () => {
    const dispatch = useDispatch();
    const {workspaceNumber} = useParams();
    const workspaces = useSelector((state) => state.workspaces);
    const cur = workspaces.current;

    useEffect(() => {
        dispatch(fetchWorkspaceStats(workspaceNumber));
    }, [dispatch, workspaceNumber]);

    if (!cur) {
        return null;
    }

    return (
        <DashboardContainer>
            <DashboardRow>
                <Col>
                    <DashboardTitle>
                        <WorkspaceTitle>
                            {cur.countryCode && (
                                <CountryFlagWrapper>
                                    <CountryFlag
                                        countryCode={cur.countryCode}
                                        width={45}
                                    />
                                </CountryFlagWrapper>
                            )}
                            <WorkspaceNameWrapper>{cur.name}</WorkspaceNameWrapper>
                        </WorkspaceTitle>
                        <WorkspaceUrlWrapper>
                            <WorkspaceUrl url={cur.websiteUrl}/>
                        </WorkspaceUrlWrapper>
                    </DashboardTitle>
                </Col>
            </DashboardRow>
            <DashboardRow>
                <Col sm={12} md={6}>
                    <RecommendationTracker/>
                </Col>
                <Col sm={12} md={6}>
                    <IndexStatsWidget/>
                </Col>
            </DashboardRow>
            <DashboardRow>
                <Col sm={12} md={6}>
                    <CrawlScope/>
                </Col>
                <Col sm={12} md={6}>
                    <IndexabilityCard/>
                </Col>
            </DashboardRow>
        </DashboardContainer>
    );
};

export default withRouter(DashboardSimple);